$navbar-padding-y: 1rem;

@import "../node_modules/bootstrap/scss/bootstrap";

@import "./home";
@import "./contacto";

.banner-principal {
    width: 100%;
    height: 50vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    overflow: hidden;
}

@media (min-width: 768px) {
    .banner-principal {
        height: calc(100vh - $navbar-size);
    }
}

.contenido {
    padding-top: 2rem;
    padding-bottom: 2rem;
    text-align: justify;
    text-justify: inter-word;
    justify-content: center;

    img {
        width: 100%;
        height: auto;
    }
}

.footer {
    color: white;
    padding-top: 1rem;
    padding-bottom: 1rem;
    
    img {
        height: 35px;
    }

    .iconos_desktop {
        display: none;
    }

    a {
        color: white;
    }
}

@media (min-width: 992px) {
    .footer {
        padding-left: 15%;
        padding-right: 15%;
        padding-top: 3rem;
        padding-bottom: 3rem;

        img {
            height: 50px;
        }
    }

    .iconos_mobile {
        display: none !important;
    }

    .iconos_desktop {
        display: block !important;
    }
}

@media (min-width: 992px) {
    .precios-y-sociales {
        flex-direction: row-reverse !important;
        align-items: center !important;
        justify-content: space-between !important;
        align-items: center !important;
    }

    .firma-artista {
        align-items: flex-end !important;
    }
}

