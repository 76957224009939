$navbar-size: calc($nav-link-height + $navbar-padding-y * 2);
$mov-banner: 960px;

@keyframes animation1 {
    0%   {transform: translateX(0);}
    50%   {transform: translateX(calc(-100% + 100vw));}
    100%   {transform: translateX(0);}
}

.espacio-1 {
    height: calc(100vh - $navbar-size);
    width: 100%;
    background-image: url("/img/home/Fondo 1920 px_Vinil Olografico.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;

    img {
        height: 100%;
        animation: animation1 15s linear infinite;
    }
}

@media (min-width: 1400px) {
    .espacio-1 {
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            animation: unset !important;
            width: 100% !important;
            height: auto !important;
        }
    }
}

.espacio-2 {
    width: 100%;
    padding-top: 3rem;
    padding-bottom: 1rem;

    img {
        width: 75%;
    }
}

@media (min-width: 992px) {
    .espacio-2 {
        padding-top: 5rem;
        padding-bottom: 1rem;
    }
}

.espacio-3 {
    img {
        width: 66%;
    }
}

@media (min-width: 992px) {
    .espacio-3 {
        img {
            width: 33%;
        }
    }
}
