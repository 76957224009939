#info-mapa {
    padding-top: 1rem;

    img {
        width: 50px;
    }

    a {
        color: black;
    }
}

#mapa_acrilart {
    width: 100%;
    height: 450px;
}

#mapa-contacto {
    width: 100vw;
}

#siguenos-en-redes {
    padding-top: 1rem;
}

@media (min-width: 992px) {
    #mapa-contacto {
        padding-left: 10%;
        padding-right: 10%;
    }

    #info-mapa {
        min-height: calc(100vh - $navbar-size);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    #mapa_acrilart {
        width: 100%;
        height: 75vh;
    }

    #siguenos-en-redes {
        padding-top: 2rem;
    }
}

